<template>
  <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
    <span class="flix-html-small">{{
      $t("time.lastMinute.description").split("{date}")[0]
    }}</span>
    <inputSelect
      :key="data"
      :settings="{ value: data, default: defaultValue, values: values }"
      :callback="setSave"
    />
    <span class="flix-html-small">{{
      $t("time.lastMinute.description").split("{date}")[1]
    }}</span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      defaultValue: "30",
      data: false,
      values: {
        "0": "0 " + this.$tc("dates.name.minutes", 0),
        "15": "15 " + this.$tc("dates.name.minutes", 15),
        "30": "30 " + this.$tc("dates.name.minutes", 30),
        "45": "45 " + this.$tc("dates.name.minutes", 45),
        "60": "1 " + this.$tc("dates.name.hours", 1),
        "120": "2 " + this.$tc("dates.name.hours", 2),
        "180": "3 " + this.$tc("dates.name.hours", 3),
        "240": "4 " + this.$tc("dates.name.hours", 4),
        "300": "5 " + this.$tc("dates.name.hours", 5),
        "360": "6 " + this.$tc("dates.name.hours", 6),
        "720": "12 " + this.$tc("dates.name.hours", 12),
        "1440": "1 " + this.$tc("dates.name.days", 1),
        "2880": "2 " + this.$tc("dates.name.days", 2)
      }
    };
  },
  computed: {},
  mounted() {
    this.data = this.getDefault();
    this.setSave(this.data);
  },
  methods: {
    setSave(ret) {
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.exclude.lastminute = this.data;
      this.$store.commit("business/prefetch", data);
    },
    getDefault() {
      if (!this.$store.state.business.unsaved.exclude.lastminute) {
        return this.default;
      }
      return this.$store.state.business.unsaved.exclude.lastminute;
    }
  }
};
</script>
<style lang="sass" scoped></style>
